import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
import { TOC, TOCList, TOCLink } from "../components/TOC";
import { AsPropWarning } from "../components/AsPropWarning";
import { Pipe } from "../components/Pipe";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Accordion" description="Accessible accordion component for React" mdxType="SEO" />
    <h1 {...{
      "id": "accordion"
    }}>{`Accordion`}</h1>
    <TOC mdxType="TOC">
  <TOCList mdxType="TOCList">
    <TOCLink href="#accordion-1" mdxType="TOCLink">Accordion</TOCLink>
    <TOCLink href="#accordionitem" mdxType="TOCLink">AccordionItem</TOCLink>
    <TOCLink href="#accordionbutton" mdxType="TOCLink">AccordionButton</TOCLink>
    <TOCLink href="#accordionpanel" mdxType="TOCLink">AccordionPanel</TOCLink>
    <TOCLink href="#useaccordioncontext" mdxType="TOCLink">useAccordionContext</TOCLink>
    <TOCLink href="#useaccordionitemcontext" mdxType="TOCLink">useAccordionItemContext</TOCLink>
  </TOCList>
    </TOC>
    <ul>
      <li parentName="ul">{`Source: `}<a parentName="li" {...{
          "href": "https://github.com/reach/reach-ui/tree/main/packages/accordion"
        }}>{`https://github.com/reach/reach-ui/tree/main/packages/accordion`}</a></li>
      <li parentName="ul">{`WAI-ARIA: `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.2/#accordion"
        }}>{`https://www.w3.org/TR/wai-aria-practices-1.2/#accordion`}</a></li>
    </ul>
    <p>{`An accordion is a vertically stacked group of collapsible sections. An accordion is composed of grouped buttons and panels. When a user selects an accordion button, its corresponding panel should switch between 'open' and 'collapsed' states.`}</p>
    <p>{`Accordions follow many consistent patterns but do allow for some variability in behavior. For example, some accordions only allow one panel to be open at a time, where others may allow multiple or all panels to be open simultaneously. Similarly, many accordions will allow all panels to be simultaneously collapsed, while others may require one panel to be open at all times.`}</p>
    <p>{`If you are familiar with the disclosure pattern, an accordion will feel very similar. The key distinction is that a disclosure is a standalone component that consists of a single button-panel-group. Because of this, you cannot navigate between different disclosures with a keyboard the same way you can with an accordion. To provide users with a predictable behavior between components, it is important to keep disclosures and accordions visually distinct across your app.`}</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/accordion`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/accordion`}</inlineCode>{`. Then import the components and styles that you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/accordion
# or
yarn add @reach/accordion
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion";
import "@reach/accordion/styles.css";
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Accordion>
      <AccordionItem>
        <h3>
          <AccordionButton>Step 1: Do a thing</AccordionButton>
        </h3>
        <AccordionPanel>
          Here are some detailed instructions about doing a thing. I am very
          complex and probably contain a lot of content, so a user can hide or
          show me by clicking the button above.
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h3>
          <AccordionButton>Step 2: Do another thing</AccordionButton>
        </h3>
        <AccordionPanel>
          Here are some detailed instructions about doing yet another thing.
          There are a lot of things someone might want to do, so I am only going
          to talk about doing that other thing. I'll let my fellow accordion
          items go into detail about even more things.
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
`}</code></pre>
    <h3 {...{
      "id": "accordion-headings"
    }}>{`Accordion Headings`}</h3>
    <p>{`With most accordion components, the `}<inlineCode parentName="p">{`AccordionPanel`}</inlineCode>{` is treated as a semantic `}<inlineCode parentName="p">{`region`}</inlineCode>{` of the document, similar to an HTML `}<inlineCode parentName="p">{`section`}</inlineCode>{` or `}<inlineCode parentName="p">{`main`}</inlineCode>{` tag. By default, we assign `}<inlineCode parentName="p">{`role="region"`}</inlineCode>{` and to each `}<inlineCode parentName="p">{`AccordionPanel`}</inlineCode>{`, along with `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` referencing the associated `}<inlineCode parentName="p">{`AccordionButton`}</inlineCode>{` component.`}</p>
    <p>{`To improve the semantics of the markup further, the ARIA guidelines dictate that each accordion item's button should be wrapped in an element with `}<inlineCode parentName="p">{`role="heading"`}</inlineCode>{`, or more simply, and HTML heading tag. Because headings are necessarily dependent on the context of their surrounding content, we do not wrap the `}<inlineCode parentName="p">{`AccordionButton`}</inlineCode>{` inside of a heading tag by default. It is up to each developer to implement this detail in a way that makes sense for their application.`}</p>
    <p>{`You can abstract a solution in a variety of ways. Perhaps you write an `}<inlineCode parentName="p">{`AccordionHeader`}</inlineCode>{` wrapper component that accepts a `}<inlineCode parentName="p">{`headingLevel`}</inlineCode>{` prop:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function AccordionHeader({ headingLevel = 2, props }) {
  let Comp = "h" + headingLevel;
  return (
    <Comp>
      <AccordionButton {...props} />
    </Comp>
  );
}
`}</code></pre>
    <p>{`You can also create a context-aware heading component so that the heading level increments appropriately as content is nested:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  const HeadingContext = createContext(2);

  function MyAccordionSection(props) {
    return (
      <div>
        <Heading>How to do a thing</Heading>
        <p>
          Below I am going to explain how you might do a thing, in two very
          important steps.
        </p>
        <Accordion>
          <ContextAwareAccordionItem>
            <AccordionHeader>Step 1: Do a thing</AccordionHeader>
            <AccordionPanel>
              Here are some detailed instructions about doing a thing. I am very
              complex and probably contain a lot of content, so a user can hide
              or show me by clicking the button above.
            </AccordionPanel>
          </ContextAwareAccordionItem>
          <AccordionItem>
            <AccordionHeader>Step 2: Do another thing</AccordionHeader>
            <AccordionPanel>
              Here are some detailed instructions about doing yet another thing.
              There are a lot of things someone might want to do, so I am only
              going to talk about doing that other thing. I'll let my fellow
              accordion items go into detail about even more things.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    );
  }

  function AccordionHeader(props) {
    return (
      <Heading>
        <AccordionButton {...props} />
      </Heading>
    );
  }

  function ContextAwareAccordionItem(props) {
    return (
      <Section>
        <AccordionItem {...props} />
      </Section>
    );
  }

  function Heading(props) {
    let Comp = "h" + Math.min(useContext(HeadingContext), 6);
    return <Comp {...props} />;
  }

  function Section(props) {
    let headingLevel = useContext(HeadingContext);
    return (
      <HeadingContext.Provider value={headingLevel + 1}>
        {props.children}
      </HeadingContext.Provider>
    );
  }

  return <MyAccordionSection />;
})();
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "accordion-1"
    }}>{`Accordion`}</h3>
    <p>{`The wrapper component for all other accordion components. Each accordion component will consist of accordion items whose buttons are keyboard navigable using arrow keys.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Accordion index={index} onChange={(value) => setIndex(value)}>
  <AccordionItem>
    <h3>
      <AccordionButton>Step 1: Do a thing</AccordionButton>
    </h3>
    <AccordionPanel>...</AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <h3>
      <AccordionButton>Step 2: Do another thing</AccordionButton>
    </h3>
    <AccordionPanel>...</AccordionPanel>
  </AccordionItem>
</Accordion>
`}</code></pre>
    <h4 {...{
      "id": "accordion-css-selectors"
    }}>{`Accordion CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-accordion] {
}
`}</code></pre>
    <h4 {...{
      "id": "collapsible-and-multiple-props"
    }}>{`collapsible and multiple props`}</h4>
    <p>{`You can use the `}<inlineCode parentName="p">{`collapsible`}</inlineCode>{` prop to dictate that an accordion should allow all panels to be collapsed simultaneously. By default, one panel must be in an `}<inlineCode parentName="p">{`open`}</inlineCode>{` state at all times.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Accordion collapsible>
      <AccordionItem>
        <h3>
          <AccordionButton>Step 1: Do a thing</AccordionButton>
        </h3>
        <AccordionPanel>
          Integer ad iaculis semper aenean nibh quisque hac eget volutpat, at
          dui sem accumsan cras congue mi varius egestas interdum, molestie
          blandit sociosqu sodales diam metus erat venenatis.
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h3>
          <AccordionButton>Step 2: Do another thing</AccordionButton>
        </h3>
        <AccordionPanel>
          Hendrerit faucibus litora justo aliquet inceptos gravida felis vel
          aenean, natoque fermentum nostra tempus ornare nam diam est, neque
          risus aliquam sapien vestibulum sociis integer eros.
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`multiple`}</inlineCode>{` prop dictates that any number of panels may be open at the same time. By default, when a user opens a new accordion item, the previously open item will be set to `}<inlineCode parentName="p">{`collapsed`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Accordion multiple>
      <AccordionItem>
        <h3>
          <AccordionButton>Step 1: Do a thing</AccordionButton>
        </h3>
        <AccordionPanel>
          Integer ad iaculis semper aenean nibh quisque hac eget volutpat, at
          dui sem accumsan cras congue mi varius egestas interdum, molestie
          blandit sociosqu sodales diam metus erat venenatis.
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h3>
          <AccordionButton>Step 2: Do another thing</AccordionButton>
        </h3>
        <AccordionPanel>
          Hendrerit faucibus litora justo aliquet inceptos gravida felis vel
          aenean, natoque fermentum nostra tempus ornare nam diam est, neque
          risus aliquam sapien vestibulum sociis integer eros.
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
`}</code></pre>
    <p>{`Using both props together dictates that any number of panels in an accordion can be `}<inlineCode parentName="p">{`open`}</inlineCode>{` or `}<inlineCode parentName="p">{`collapsed`}</inlineCode>{` at any time without regard to the state of other accordion items. These props are only relevant for uncontrolled accordion components, as the state of controlled accordion items is determined only by the `}<inlineCode parentName="p">{`index`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Accordion collapsible multiple>
      <AccordionItem>
        <h3>
          <AccordionButton>Step 1: Do a thing</AccordionButton>
        </h3>
        <AccordionPanel>
          Integer ad iaculis semper aenean nibh quisque hac eget volutpat, at
          dui sem accumsan cras congue mi varius egestas interdum, molestie
          blandit sociosqu sodales diam metus erat venenatis.
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h3>
          <AccordionButton>Step 2: Do another thing</AccordionButton>
        </h3>
        <AccordionPanel>
          Hendrerit faucibus litora justo aliquet inceptos gravida felis vel
          aenean, natoque fermentum nostra tempus ornare nam diam est, neque
          risus aliquam sapien vestibulum sociis integer eros.
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
`}</code></pre>
    <h4 {...{
      "id": "controlled-accordion"
    }}>{`Controlled Accordion`}</h4>
    <p>{`If you want to control the accordion's open panels, you can do so by passing `}<a parentName="p" {...{
        "href": "#accordion-index"
      }}><inlineCode parentName="a">{`index`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "#accordion-onchange"
      }}><inlineCode parentName="a">{`onChange`}</inlineCode></a>{` props. The index corresponds with the order of each accordion item as they appear within an accordion. The index value passed sets its corresponding panel to an `}<inlineCode parentName="p">{`open`}</inlineCode>{` state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const [index, setIndex] = React.useState(0);
return (
  <Accordion index={index} onChange={(value) => setIndex(value)}>
    <AccordionItem {...items[0].props} />
    <AccordionItem {...items[1].props} />
    <AccordionItem {...items[2].props} />
  </Accordion>
);
`}</code></pre>
    <p>{`In a controlled accordion, multiple items can be set to `}<inlineCode parentName="p">{`open`}</inlineCode>{` by passing an array of indices to the `}<inlineCode parentName="p">{`index`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const [indices, setIndices] = React.useState([0, 2]);
function toggleItem(toggledIndex) {
  if (indices.includes(toggledIndex)) {
    setIndices(indices.filter((currentIndex) => currentIndex !== toggledIndex));
  } else {
    setIndices([...indices, toggledIndex].sort());
  }
}

return (
  <Accordion index={indices} onChange={toggleItem}>
    <AccordionItem {...items[0].props} />
    <AccordionItem {...items[1].props} />
    <AccordionItem {...items[2].props} />
  </Accordion>
);
`}</code></pre>
    <h4 {...{
      "id": "accordion-props"
    }}>{`Accordion Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#accordion-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#accordion-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#accordion-collapsible"
            }}><inlineCode parentName="a">{`collapsible`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#accordion-defaultindex"
            }}><inlineCode parentName="a">{`defaultIndex`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`number[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#accordion-index"
            }}><inlineCode parentName="a">{`index`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`number[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#accordion-multiple"
            }}><inlineCode parentName="a">{`multiple`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#accordion-onchange"
            }}><inlineCode parentName="a">{`onChange`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#accordion-readonly"
            }}><inlineCode parentName="a">{`readOnly`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "accordion-as"
    }}>{`Accordion as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`A string representing an HTML element or a React component that will tell the `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{` what element to render. Defaults to `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>
    <AsPropWarning mdxType="AsPropWarning" />
    <h5 {...{
      "id": "accordion-children"
    }}>{`Accordion children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p><inlineCode parentName="p">{`Accordion`}</inlineCode>{` can accept `}<inlineCode parentName="p">{`AccordionItem`}</inlineCode>{` components as children.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Accordion>
  <AccordionItem>
    <AccordionButton />
    <AccordionPanel />
  </AccordionItem>
  <AccordionItem>
    <AccordionButton />
    <AccordionPanel />
  </AccordionItem>
</Accordion>
`}</code></pre>
    <h5 {...{
      "id": "accordion-collapsible"
    }}>{`Accordion collapsible`}</h5>
    <p><inlineCode parentName="p">{`collapsible?: boolean`}</inlineCode></p>
    <p>{`Whether or not all panels of an uncontrolled accordion can be toggled to a closed state. See the `}<a parentName="p" {...{
        "href": "#collapsible-and-multiple-props"
      }}><inlineCode parentName="a">{`collapsible`}</inlineCode>{` and `}<inlineCode parentName="a">{`multiple`}</inlineCode>{` props`}</a>{` section for details. Defaults to `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "accordion-defaultindex"
    }}>{`Accordion defaultIndex`}</h5>
    <p><inlineCode parentName="p">{`defaultIndex?: number | number[]`}</inlineCode></p>
    <p>{`A default value for the open panel's index or indices in an uncontrolled accordion component when it is initially rendered.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Accordion defaultIndex={1}>
  <AccordionItem>
    <AccordionButton />
    <AccordionPanel>I will be closed on the initial render!</AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <AccordionButton />
    <AccordionPanel>I will be open on the initial render!</AccordionPanel>
  </AccordionItem>
</Accordion>
`}</code></pre>
    <p>{`If an accordion has no defaultIndex, the initially rendered open panel depends on the `}<inlineCode parentName="p">{`collapsible`}</inlineCode>{` prop.`}</p>
    <ul>
      <li parentName="ul">{`If `}<inlineCode parentName="li">{`collapsible`}</inlineCode>{` is set to `}<inlineCode parentName="li">{`true`}</inlineCode>{`, without a `}<inlineCode parentName="li">{`defaultIndex`}</inlineCode>{` no panels will initially be open. Otherwise, the first panel at index `}<inlineCode parentName="li">{`0`}</inlineCode>{` will initially be open.`}</li>
    </ul>
    <p>{`You can only pass an array of indices to `}<inlineCode parentName="p">{`defaultIndex`}</inlineCode>{` if you also set `}<inlineCode parentName="p">{`multiple`}</inlineCode>{` to true.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Accordion defaultIndex={[0, 1]} multiple>
  <AccordionItem>
    <AccordionButton />
    <AccordionPanel>I will be open on the initial render!</AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <AccordionButton />
    <AccordionPanel>I will also be open on the initial render!</AccordionPanel>
  </AccordionItem>
</Accordion>
`}</code></pre>
    <h5 {...{
      "id": "accordion-index"
    }}>{`Accordion index`}</h5>
    <p><inlineCode parentName="p">{`index?: number | number[]`}</inlineCode></p>
    <p>{`The index or array of indices for open accordion panels. The `}<inlineCode parentName="p">{`index`}</inlineCode>{` prop should be used along with `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` to create controlled accordion components.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const [indices, setIndices] = React.useState([0, 2]);
function toggleItem(toggledIndex) {
  if (indices.includes(toggledIndex)) {
    setIndices(indices.filter((currentIndex) => currentIndex !== toggledIndex));
  } else {
    setIndices([...indices, toggledIndex].sort());
  }
}

return (
  <Accordion index={indices} onChange={toggleItem}>
    <AccordionItem {...items[0].props} />
    <AccordionItem {...items[1].props} />
    <AccordionItem {...items[2].props} />
  </Accordion>
);
`}</code></pre>
    <h5 {...{
      "id": "accordion-multiple"
    }}>{`Accordion multiple`}</h5>
    <p><inlineCode parentName="p">{`multiple?: boolean`}</inlineCode></p>
    <p>{`Whether or not multiple panels in an uncontrolled accordion can be opened at the same time. See the `}<a parentName="p" {...{
        "href": "#collapsible-and-multiple-props"
      }}><inlineCode parentName="a">{`collapsible`}</inlineCode>{` and `}<inlineCode parentName="a">{`multiple`}</inlineCode>{` props`}</a>{` section for details. Defaults to `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "accordion-onchange"
    }}>{`Accordion onChange`}</h5>
    <p><inlineCode parentName="p">{`onChange?: (value: number) => void`}</inlineCode></p>
    <p>{`The callback that is fired when an accordion item's open state is changed.`}</p>
    <h5 {...{
      "id": "accordion-readonly"
    }}>{`Accordion readOnly`}</h5>
    <p><inlineCode parentName="p">{`readOnly?: boolean`}</inlineCode></p>
    <p>{`Whether or not an uncontrolled accordion is read-only (meaning that the user cannot toggle its state with a normal interaction). Defaults to `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <p>{`Generally speaking, you probably want to avoid this, as it can be confusing especially when navigating by keyboard. However, this may be useful if you want to lock an accordion under certain conditions (perhaps user authentication is required to access the content). In these instances, you may want to include an alert when a user tries to activate a read-only accordion panel to let them know why it does not toggle as may be expected.`}</p>
    <h3 {...{
      "id": "accordionitem"
    }}>{`AccordionItem`}</h3>
    <p>{`A group that wraps an accordion's button and panel components.`}</p>
    <h4 {...{
      "id": "accordionitem-css-selectors"
    }}>{`AccordionItem CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-accordion-item] {
  /* styles for all accordion items */
}
[data-reach-accordion-item][data-state="open"] {
  /* styles for all open accordion items */
}
[data-reach-accordion-item][data-state="collapsed"] {
  /* styles for all collapsed accordion items */
}
[data-reach-accordion-item][data-disabled] {
  /* styles for all disabled accordion items */
}
[data-reach-accordion-item][data-read-only] {
  /* styles for all read-only accordion items */
}
`}</code></pre>
    <h4 {...{
      "id": "accordionitem-props"
    }}>{`AccordionItem Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#accordionitem-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#accordionitem-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#accordionitem-disabled"
            }}><inlineCode parentName="a">{`disabled`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "accordionitem-as"
    }}>{`AccordionItem as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`A string representing an HTML element or a React component that will tell the `}<inlineCode parentName="p">{`AccordionItem`}</inlineCode>{` what element to render. Defaults to `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>
    <AsPropWarning mdxType="AsPropWarning" />
    <h5 {...{
      "id": "accordionitem-children"
    }}>{`AccordionItem children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`An `}<inlineCode parentName="p">{`AccordionItem`}</inlineCode>{` expects to receive an `}<inlineCode parentName="p">{`AccordionButton`}</inlineCode>{` and `}<inlineCode parentName="p">{`AccordionPanel`}</inlineCode>{` components as its children, though you can also nest other components within an `}<inlineCode parentName="p">{`AccordionItem`}</inlineCode>{` if you want some persistant content that is relevant to the section but not collapsible when the `}<inlineCode parentName="p">{`AccordionButton`}</inlineCode>{` is toggled.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Accordion defaultIndex={[0, 1]} multiple>
  <AccordionItem>
    <AccordionButton>Step 1: Do this important thing</AccordionButton>
    <AccordionPanel>Detailed instructions about step 1.</AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <AccordionButton>Step 2: Do this other important thing</AccordionButton>
    <AccordionPanel>Detailed instructions about step 1.</AccordionPanel>
    {/* the following component will not collapse! */}
    <SomeCalloutBox>
      Important: you should always consult the user manual before doing step 2!
    </SomeCalloutBox>
  </AccordionItem>
</Accordion>
`}</code></pre>
    <h5 {...{
      "id": "accordionitem-disabled"
    }}>{`AccordionItem disabled`}</h5>
    <p><inlineCode parentName="p">{`disabled?: boolean`}</inlineCode></p>
    <p>{`Whether or not an accordion panel is disabled from user interaction. Defaults to `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "accordionbutton"
    }}>{`AccordionButton`}</h3>
    <p>{`The trigger button a user clicks to interact with an accordion.`}</p>
    <h4 {...{
      "id": "accordionbutton-css-selectors"
    }}>{`AccordionButton CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-accordion-button] {
  /* styles for buttons in all accordion items */
}
[data-reach-accordion-button][aria-expanded] {
  /* styles for buttons in open accordion items */
}
[data-reach-accordion-button][disabled] {
  /* styles for all buttons in disabled accordion items */
}
`}</code></pre>
    <h4 {...{
      "id": "accordionbutton-props"
    }}>{`AccordionButton Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#accordionbutton-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#accordionbutton-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "accordionbutton-as"
    }}>{`AccordionButton as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`A string representing an HTML element or a React component that will tell the `}<inlineCode parentName="p">{`AccordionButton`}</inlineCode>{` what element to render. Defaults to `}<inlineCode parentName="p">{`button`}</inlineCode>{`.`}</p>
    <AsPropWarning mdxType="AsPropWarning" />
    <h5 {...{
      "id": "accordionbutton-children"
    }}>{`AccordionButton children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`Typically a text string that serves as a label for the accordion, though nested DOM nodes can be passed as well so long as they are valid children of interactive elements.`}</p>
    <p>{`If you need to group interactive elements within an accordion's button, we recommend grouping the button inside of a wrapper element rather than including invalid HTML inside the button:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  function Example() {
    return (
      <Accordion>
        <AccordionItem>
          <GroupedAccordionHeader>Option 1</GroupedAccordionHeader>
          <StyledAccordionPanel>
            Ante rhoncus facilisis iaculis nostra faucibus vehicula ac
            consectetur pretium, lacus nunc consequat id viverra facilisi ligula
            eleifend, congue gravida malesuada proin scelerisque luctus est
            convallis.
          </StyledAccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <GroupedAccordionHeader>Option 2</GroupedAccordionHeader>
          <StyledAccordionPanel>
            Ante rhoncus facilisis iaculis nostra faucibus vehicula ac
            consectetur pretium, lacus nunc consequat id viverra facilisi ligula
            eleifend, congue gravida malesuada proin scelerisque luctus est
            convallis.
          </StyledAccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <GroupedAccordionHeader>Option 3</GroupedAccordionHeader>
          <StyledAccordionPanel>
            Ante rhoncus facilisis iaculis nostra faucibus vehicula ac
            consectetur pretium, lacus nunc consequat id viverra facilisi ligula
            eleifend, congue gravida malesuada proin scelerisque luctus est
            convallis.
          </StyledAccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  }

  function StyledAccordionPanel(props) {
    return <AccordionPanel style={{ padding: 10 }} {...props} />;
  }

  function GroupedAccordionHeader({ children }) {
    return (
      <div
        style={{
          alignItems: "center",
          background: "#eee",
          border: "1px solid #888",
          borderRadius: 3,
          margin: "9px 0",
          display: "flex",
          justifyContent: "space-between",
          padding: "4px 10px",
        }}
      >
        <AccordionButton
          style={{
            appearance: "none",
            background: 0,
            border: 0,
            boxShadow: "none",
            color: "inherit",
            display: "block",
            textAlign: "inherit",
            flexGrow: 1,
            flexShrink: 0,
            font: "inherit",
            fontWeight: "bolder",
            margin: 0,
            padding: "10px 0",
          }}
        >
          {children}
        </AccordionButton>
        <Menu>
          <MenuButton style={{ margin: 0 }}>
            <span>Actions</span>
          </MenuButton>
          <MenuList>
            <MenuItem onSelect={() => console.log("Download")}>
              Download
            </MenuItem>
            <MenuItem onSelect={() => console.log("Copy")}>
              Create a Copy
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
    );
  }
  return <Example />;
})();
`}</code></pre>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Further reading:`}</strong>{` `}<a parentName="li" {...{
          "href": "https://adrianroselli.com/2016/12/be-wary-of-nesting-roles.html"
        }}><em parentName="a">{`Be Wary of Nesting Roles`}</em>{` by Adrian Roselli`}</a></li>
    </ul>
    <h3 {...{
      "id": "accordionpanel"
    }}>{`AccordionPanel`}</h3>
    <p>{`The collapsible panel in which inner content for an accordion item is rendered.`}</p>
    <h4 {...{
      "id": "accordionpanel-css-selectors"
    }}>{`AccordionPanel CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-accordion-panel] {
  /* styles for all accordion panels */
}
[data-reach-accordion-panel][data-state="open"] {
  /* styles for all open accordion panels */
}
[data-reach-accordion-panel][data-state="collapsed"] {
  /* styles for all collapsed accordion panels */
}
[data-reach-accordion-panel][data-disabled] {
  /* styles for all disabled accordion panels */
}
`}</code></pre>
    <h4 {...{
      "id": "accordionpanel-props"
    }}>{`AccordionPanel Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#accordionpanel-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#accordionpanel-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "accordionpanel-as"
    }}>{`AccordionPanel as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`A string representing an HTML element or a React component that will tell the `}<inlineCode parentName="p">{`AccordionPanel`}</inlineCode>{` what element to render. Defaults to `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>
    <AsPropWarning mdxType="AsPropWarning" />
    <h5 {...{
      "id": "accordionpanel-children"
    }}>{`AccordionPanel children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`Inner collapsible content for the accordion item.`}</p>
    <h3 {...{
      "id": "useaccordioncontext"
    }}>{`useAccordionContext`}</h3>
    <p><inlineCode parentName="p">{`function useAccordionContext(): { id: string | undefined; openPanels: number[] }`}</inlineCode></p>
    <p>{`A hook that exposes data for a given `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{` component to its descendants.`}</p>
    <h3 {...{
      "id": "useaccordionitemcontext"
    }}>{`useAccordionItemContext`}</h3>
    <p><inlineCode parentName="p">{`function useAccordionItemContext(): { index: number; isExpanded: boolean }`}</inlineCode></p>
    <p>{`A hook that exposes data for a given `}<inlineCode parentName="p">{`AccordionItem`}</inlineCode>{` component to its descendants.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      